/* eslint-disable react/jsx-curly-newline */
import { isMarketingPrefEnabledPlatform } from '@oup/shared-node-browser/marketingPrefUtils.js';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@oup/shared-front-end/src/components/Button';
import colors from '../../globals/colors.js';
// TODO: Fetch these from Lambda/CMS:
// This is the first form in the EDIT CLASS journey.

// Used for getting Regex
import appSettings from '../../globals/appSettings.js';

// Action imports
import { setFormState } from '../../redux/reducers/data/forms.reducer.js';
import checkGlobalEmailValidator from '../../redux/sagas/appFunctions/formHandlers/asyncValidators/checkGlobalEmail.js';

// Component imports
import Validation from '../../components/Validation/Validation.js';
import Card from '../../components/Card/Card.js';
import TextInput from '../../components/TextInput/TextInput.js';
import Checkbox from '../../components/Checkbox/Checkbox.js';
import PageHeading from '../../components/PageHeading/PageHeading.js';

import ConnectedField from '../CreateOrganisation/formUtil/ConnectedField.js';
import FormValidity from '../CreateOrganisation/formUtil/FormValidity.js';
import UserAgreement from '../../components/UserAgreement/UserAgreement';
import { featureIsEnabled } from '../../globals/envSettings';
import MarketingPreferencesConsentForm from '../../components/MarketingPreferencesConsentForm/MarketingPreferencesConsentForm';
import {
  setMarketPref,
  setTermsAndConditions,
  setUnderAgeAccepted
} from '../../redux/reducers/registration/registration.reducer';

import cmsContent from '../../utils/cmsContent.js';

function IncompleteAccountForm({
  missingFields,
  firstName,
  submitForm,
  platform,
  isTandCaccepted,
  setUnderAgeAcceptedAction,
  setTermsAndConditionsAction,
  setMarketPrefAction
}) {
  const CMS = cmsContent.registrationPage || {};
  const [isTermsAndConditionsValid, setTAndC] = useState(false);
  return (
    <div className="row">
      <div className="col">
        {isTandCaccepted && missingFields.includes('marketingPref') ? (
          <PageHeading
            title={(CMS.pageSubtitle_update_account || '').replace('{firstName}', firstName)}
            subtitle={CMS.complete_marketing_preferences}
          />
        ) : (
          <PageHeading
            title={(CMS.pageSubtitle_update_account || '').replace('{firstName}', firstName)}
            subtitle={missingFields.includes('email') ? CMS.confirm_email_address_text : CMS.accept_conditions_text}
          />
        )}
      </div>

      <div className="col sm8 gin-top4">
        <Card headingText={CMS.registration_title} headingColor={colors.PROFILE}>
          {missingFields.includes('email') && (
            <ConnectedField
              formName="incompleteAccount"
              fieldName={TextInput.defaultsFor().email.id}
              positiveValidationFunction={value => appSettings.EMAIL_REGEX.test(value)}
              negativeValidationFunction={value => !appSettings.EMAIL_REGEX.test(value)}
              getValidationMessage={value => {
                if (!value) {
                  return CMS.please_enter_valid_username;
                }

                if (!appSettings.EMAIL_REGEX.test(value)) {
                  return CMS.invalid_email_text;
                }

                return null;
              }}
              asyncValidationFunction={value =>
                checkGlobalEmailValidator(value).then(result => {
                  if (result === 'VALID') {
                    return {
                      isValid: true,
                      isInvalid: false,
                      isWarning: false,
                      message: null
                    };
                  }

                  if (result === 'INVALID') {
                    return {
                      isValid: false,
                      isInvalid: true,
                      isWarning: false,
                      message: CMS.already_registered_username
                    };
                  }

                  return {
                    isValid: false,
                    isInvalid: false,
                    isWarning: true,
                    message: CMS.unable_to_validate
                  };
                })
              }
              render={(id, value, onChange, onBlur, message, isValid, isError, onPaste, isWaiting, isWarning) => (
                <Validation
                  isValid={isValid}
                  isError={isError}
                  isWaiting={isWaiting}
                  isWarning={isWarning}
                  message={message}
                  forId={id}
                >
                  <TextInput {...TextInput.defaultsFor().email} value={value} onChange={onChange} onBlur={onBlur} />
                </Validation>
              )}
            />
          )}
          {featureIsEnabled('enable-marketing-preferences') && isMarketingPrefEnabledPlatform(platform) ? (
            <>
              <div className="pad-top2">
                <MarketingPreferencesConsentForm
                  platform={platform}
                  isTandCaccepted={isTandCaccepted}
                  setMarketTermsAndConditions={value => {
                    setTermsAndConditionsAction(value);
                    setTAndC(value);
                  }}
                  setUnderAgeAccepted={value => {
                    setUnderAgeAcceptedAction(value);
                  }}
                  setMarketPreference={value => {
                    setMarketPrefAction(value);
                  }}
                />
              </div>
              <div className="pad-top2">
                <UserAgreement personalInformation />
              </div>
              <div className="gin-top4">
                {missingFields.includes('email') ? (
                  <FormValidity
                    formName="incompleteAccount"
                    requiredFields={[TextInput.defaultsFor().email.id]}
                    render={valid => (
                      <Button
                        id="registration-submit"
                        variant="filled"
                        text={CMS.button_registration_submit_update_text}
                        onClick={submitForm}
                        disabled={!valid || (!isTermsAndConditionsValid && !isTandCaccepted)}
                      />
                    )}
                  />
                ) : (
                  <FormValidity
                    formName="incompleteAccount"
                    render={() => (
                      <Button
                        id="registration-submit"
                        variant="filled"
                        text={CMS.button_registration_submit_update_text}
                        onClick={submitForm}
                        disabled={!isTermsAndConditionsValid && !isTandCaccepted}
                      />
                    )}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <h4 className="pad-top2x">
                <em>{TextInput.defaultsFor().termsAndConditions.groupLabel}</em>
              </h4>

              <ConnectedField
                formName="incompleteAccount"
                fieldName={TextInput.defaultsFor().termsAndConditions.id}
                reportErrorImmediately
                positiveValidationFunction={value => value}
                negativeValidationFunction={value => !value}
                getValidationMessage={value => {
                  if (!value) {
                    return 'Please agree to our Terms and Conditions.';
                  }
                  return null;
                }}
                render={(id, value, onChange, onBlur, message, isValid, isError) => (
                  <Validation forId={id} isValid={isValid} isError={isError} message={message} noLabelAboveField>
                    <Checkbox
                      {...TextInput.defaultsFor().termsAndConditions}
                      value={value}
                      onChange={onChange}
                      required
                    />
                  </Validation>
                )}
              />

              <p className="pad-top2">
                <UserAgreement dataUsage privacyPolicy />
              </p>
              <div className="gin-top4">
                <FormValidity
                  formName="incompleteAccount"
                  requiredFields={
                    missingFields.includes('email')
                      ? [TextInput.defaultsFor().email.id, TextInput.defaultsFor().termsAndConditions.id]
                      : [TextInput.defaultsFor().termsAndConditions.id]
                  }
                  render={valid => (
                    <Button
                      id="registration-submit"
                      variant="filled"
                      text={CMS.button_registration_submit_update_text}
                      onClick={submitForm}
                      disabled={!valid}
                    />
                  )}
                />
              </div>
            </>
          )}
          <input type="hidden" name="platformCode" value={platform} />
        </Card>
      </div>
    </div>
  );
}

IncompleteAccountForm.propTypes = {
  missingFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  firstName: PropTypes.string.isRequired,
  submitForm: PropTypes.func.isRequired,
  platform: PropTypes.string,
  isTandCaccepted: PropTypes.string,
  isTermsAndConditionsValid: PropTypes.bool.isRequired,
  setTermsAndConditionsAction: PropTypes.func,
  setMarketPrefAction: PropTypes.func,
  setUnderAgeAcceptedAction: PropTypes.func
};

export default connect(
  state => ({
    missingFields: state.identity.missingFields,
    firstName: state.people.data[state.identity.userId].firstname
  }),
  {
    submitForm: () => setFormState('incompleteAccount', 'SUBMITTING'),
    setTermsAndConditionsAction: setTermsAndConditions,
    setMarketPrefAction: setMarketPref,
    setUnderAgeAcceptedAction: setUnderAgeAccepted
  }
)(IncompleteAccountForm);
