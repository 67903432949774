import jsonFetch from '../util/jsonFetch';

export default async confirmedProducts => {
  const token = sessionStorage.getItem('token');
  const url = `${__API_BASE__}/open/ltitool1v3/content-selection-confirmation`;
  const items = confirmedProducts.map(product => ({
    isbnLaunch: product.isbnLaunch,
    isbnLicense: product.isbnLicense,
    addContentItems: !!product.addContentItems,
    addGradeItems: !!product.addGradeItems,
    ...(product.selectedLinks && {
      selectedLinks: product.selectedLinks.map(link => {
        if (link.isClassRoom) {
          return {
            isClassRoom: true
          };
        }
        if (product.userType === 'STUDENT') {
          return {
            lessonId: link.lessonId
          };
        }
        return {
          activityId: link.activityId
        };
      })
    }),
    ...(product.addContentItems &&
      product.olbDetails && {
        startPage: product.selectedStartPage,
        endPage: product.selectedEndPage
      })
  }));
  const payload = { token, items };
  const headers = { 'Content-Type': 'application/json' };
  const body = JSON.stringify(payload);

  const response = await jsonFetch('ltiGetJwtAndDeeplinkReturnUrl', [url, { method: 'POST', headers, body }], false);
  return response;
};
