import * as t from '../actionTypes';

export const mode = {
  INPUT: 'INPUT',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  EMPTY: 'EMPTY'
};

const initialState = {
  organization: {
    mode: mode.INPUT,
    formData: {},
    errors: {}
  },
  products: {}
};

function testCredits(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_TEST_CREDITS_REQUEST:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.productId]: {
            ...state.products[payload.productId],
            mode: mode.LOADING
          }
        }
      };
    case t.GET_TEST_CREDITS_SUCCESS:
      return {
        ...state,
        products: payload.testCredits
      };
    case t.GET_TEST_CREDITS_EMPTY_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.productId]: {
            ...state.products[payload.productId],
            mode: mode.EMPTY
          }
        }
      };
    case t.GET_TEST_CREDITS_FAILURE:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.productId]: {
            ...state.products[payload.productId],
            mode: mode.ERROR,
            errors: payload.errors
          }
        }
      };
    default:
      return state;
  }
}

export default testCredits;
