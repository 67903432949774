/* eslint-disable react/jsx-curly-newline */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import get from 'lodash.get';
import APP_CONSTANTS, { ORG_REGISTRATION_STATUS } from '@oup/shared-node-browser/constants';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import TextInput from '@oup/shared-front-end/src/components/TextInput';
import Select from '@oup/shared-front-end/src/components/Select';
import TextInputWithButton from '@oup/shared-front-end/src/components/TextInputWithButton/TextInputWithButton.js';
import { featureIsEnabled } from '../../../../../../globals/envSettings';
import { isHubMode, isEltAssessmentMode, isOrbMode, isOicMode } from '../../../../../../utils/platform.js';
import styles from './enrollUser.scss';

import {
  setFirstNameValue,
  setLastNameValue,
  setEmailValue,
  setContextValue,
  setRoleValue,
  firstnameOnBlur,
  lastnameOnBlur,
  emailOnBlur,
  roleOnBlur,
  resetRoleValues,
  showBulkUserInput,
  submitForm,
  addToClassView,
  removeClass,
  generateUsername,
  clearGeneratedUsername,
  resetUsername,
  resetForm
} from '../../../../../../redux/reducers/enrolUser.reducer.js';
import { setClassroomSelected } from '../../../../../../redux/reducers/organisationPage.reducer.js';

// Component imports
import PopoutNavFooter from '../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import PanelHeading from '../../../../../../components/PanelHeading/PanelHeading.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import Label from '../../../../../../components/Label/Label';

import ImportCTA from '../../../../../../components/ImportCTA/ImportCTA.js';
import SVGIcon, { GLYPHS } from '../../../../../../components/SVGIcon/SVGIcon.js';

import userRoles, {
  orgUserRolesHighToLow,
  oupUserRolesHighToLow,
  userRoleFriendlyNames,
  externalContentRolesHighToLow,
  getRolesAllowedToRegister,
  isOupStaff
} from '../../../../../../globals/userRoles.js';
import withLocalizedContent from '../../../../../../language/withLocalizedContent';
import SelectionList from '../../../../../../components/SelectionList/SelectionList';
import colors from '../../../../../../globals/colors';
import Lozenge from '../../../../../../components/Lozenge/Lozenge';
import { orgRoles } from '../../../../../../globals/orgRoles.js';
// Constants
import enrollUserConstants from './enrolUserConstants';

const getFormattedUserRoles = roles => {
  const allOupAndOrgRoles = [
    ...orgUserRolesHighToLow,
    userRoles.OUP_CONTENT,
    ...oupUserRolesHighToLow,
    ...externalContentRolesHighToLow
  ];

  return allOupAndOrgRoles
    .filter(role => roles.includes(role))
    .map(role => ({
      value: role,
      text: userRoleFriendlyNames(role)
        .split(' ')
        // hacky capitalize by word, relatively harmless but strange
        // because caps are different everywhere else in the whole suite of EPS clients
        // (set by sharedTerms or specifically in this case by inheritance via userRoleList.glossary)
        // so perhaps it would be better to stay consistent or change how we capitalise everywhere
        .map(item => item.substring(0, 1).toUpperCase() + item.substring(1))
        .join(' ')
    }));
};

function EnrolUserInputting({
  enrolUserContext,
  setContext,
  context,
  setRole,
  roleOnBlurAction,
  roleValue,
  resetRole,
  emailOnBlurAction,
  invitePending,
  localizedContent: { enrolUserPanel: content },
  organisationName,
  addToClassViewAction,
  selectedIds,
  classrooms,
  removeClassIdAction,
  currentOrganisationLti,
  closePanel,
  currentUserRole,
  canBulkUpload,
  orgRole,
  orgId,
  // Input Values
  firstNameValue,
  firstNameIsValid,
  firstNameIsInvalid,
  firstNameErrorMessage,
  lastNameValue,
  lastNameIsValid,
  lastNameIsInvalid,
  lastNameErrorMessage,
  emailValue,
  emailIsValid,
  emailIsInvalid,
  emailErrorMessage,
  userExists,
  userHasNamesSet,
  roleIsValid,
  // On Changes
  setFirstName,
  setLastName,
  setEmail,
  // Validated input onBlurs
  firstnameOnBlurAction,
  lastnameOnBlurAction,
  // Form submission action
  bulkUserInputAction,
  submitFormAction,
  organisation,
  administrationRightsSupportLink,
  generateUsernameAction,
  clearGeneratedUsernameAction,
  usernameGenerating,
  usernameGenerated,
  username,
  hideAddToClassField,
  backAction,
  resetFormAction,
  resetUsernameAction,
  isPlacementTest
}) {
  useEffect(() => {
    if (!enrolUserContext) setContext(context);

    if (context === 'ORG_STUDENTS') {
      setRole(userRoles.LEARNER);
      roleOnBlurAction();
    } else if (roleValue === userRoles.LEARNER) {
      // for cases when a student was attempted to be added using the 'Add Student' button but the panel
      // was closed and then a member of staff was attempted to be added using the 'Add Staff' button
      resetRole();
    }
  }, []);

  const withSupervisedUser = () => {
    const isSupervisedEnabled = featureIsEnabled('supervised-users');
    const isStudentView = context === 'ORG_STUDENTS';

    return isSupervisedEnabled && isStudentView && isHubMode();
  };

  const handleUsernameOnBlur = () => emailOnBlurAction();

  const getButtonText = () => {
    switch (true) {
      case context === 'ORG_ADMIN':
        return content.inputForm_button_add_user_text;
      case invitePending:
        return content.inputForm_button_send_reminder_text;
      case withSupervisedUser():
        return content.inputForm_button_add_student_text;
      case context === 'ORG_STAFF':
        return content.inputForm_button_add_staff_text;
      default:
        return content.inputForm_button_invite_user_text;
    }
  };

  const showAddToClassView = () => addToClassViewAction();
  const renderAddToClassField = () => {
    // Render this part of code on ENVs such as DEV and TEST
    if (!isOicMode() && !currentOrganisationLti) {
      return (
        <div>
          <Label text={content.enrol_user_add_to_class} className={enrollUserConstants.ADD_TO_CLASS_CLASSNAME} />
          <SelectionList linkText={content.enrol_user_choose_classes_text} linkAction={showAddToClassView}>
            {selectedIds && selectedIds.length
              ? selectedIds.map(id => (
                  <Lozenge
                    key={id}
                    id={id}
                    text={classrooms[id].name}
                    backgroundColor={colors.TEACHER}
                    buttonAction={() => removeClassIdAction(id)}
                  />
                ))
              : null}
          </SelectionList>
        </div>
      );
    }

    // If ENV is higher than TEST, return null
    return null;
  };
  const getSupervisedUserButtonData = () => {
    if (!usernameGenerated && !usernameGenerating) {
      return {
        buttonDisabled: !firstNameValue || !lastNameValue,
        buttonText: content.generate_username_btn,
        buttonAction: () =>
          generateUsernameAction({
            orgId,
            firstName: firstNameValue,
            lastName: lastNameValue
          }),
        buttonTooltip: !firstNameValue || !lastNameValue ? content.generate_username_button_tooltip : ''
      };
    }
    if (usernameGenerated) {
      return {
        buttonDisabled: false,
        buttonText: content.clear_generated_username_btn,
        buttonAction: clearGeneratedUsernameAction
      };
    }
    return {
      buttonDisabled: true,
      buttonIcon: <SVGIcon glyph={GLYPHS.ICON_LOADING} />,
      buttonText: '',
      buttonAction: () => {}
    };
  };
  const renderPanelHeading = () => {
    if (context === 'ORG_STUDENTS') {
      return (
        <PanelHeading
          title={content.inputForm_add_students_title}
          subtitle={content.inputForm_add_students_subtitle.replace('{organisationName}', `<b>${organisationName}</b>`)}
        />
      );
    }
    return (
      <PanelHeading
        title={content.inputForm_add_staff_title}
        subtitle={content.inputForm_add_staff_subtitle.replace('{organisationName}', `<b>${organisationName}</b>`)}
      />
    );
  };

  if (
    !roleValue &&
    organisation.registrationStatus === ORG_REGISTRATION_STATUS.PARTIALLY_REGISTERED &&
    isOupStaff(currentUserRole)
  ) {
    setRole(userRoles.ORG_ADMIN);
  }

  const isLearner = roleValue === userRoles.LEARNER;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={content.close_panel_text} action={closePanel} />
            </div>
            {renderPanelHeading()}
          </div>
        }
        footerContent={
          <PopoutNavFooter
            nextButtonText={getButtonText()}
            nextAction={submitFormAction}
            nextButtonDisabled={
              userExists || !(firstNameIsValid && lastNameIsValid && (emailIsValid || username) && roleIsValid)
            }
            backButtonText={content.enroll_user_add_classname_cancel_button}
            backType="button"
            backAction={() => {
              if (backAction) backAction();
              else closePanel();
              resetFormAction();
            }}
          />
        }
      >
        <div className={styles.inputContainer}>
          {canBulkUpload && context !== 'ORG_ADMIN' ? (
            <ImportCTA
              importText={isLearner ? content.inputForm_import_text : content.inputForm_import_text_staff}
              buttonText={isLearner ? content.inputForm_button_import_text : content.inputForm_button_import_text_staff}
              bulkInputAction={bulkUserInputAction}
            />
          ) : null}
          <TextInput
            id="firstname_text_input"
            label={content.inputForm_first_name_label}
            isValid={firstNameIsInvalid !== null ? !firstNameIsInvalid : undefined}
            validationMessage={!(firstNameIsValid && !firstNameIsInvalid) ? firstNameErrorMessage : ''}
            placeholder={content.inputForm_placeholder_firstname}
            value={firstNameValue}
            onChange={value => {
              setFirstName(value);
              firstnameOnBlurAction();
            }}
            onBlur={firstnameOnBlurAction}
            disabled={
              featureIsEnabled('disable-auto-complete-existing-user-invite')
                ? invitePending || userHasNamesSet
                : invitePending || userExists || userHasNamesSet
            }
          />
          <TextInput
            id="lastname_text_input"
            label={content.inputForm_last_name_label}
            isValid={lastNameIsInvalid !== null ? !lastNameIsInvalid : undefined}
            validationMessage={!(lastNameIsValid && !lastNameIsInvalid) ? lastNameErrorMessage : ''}
            placeholder={content.inputForm_placeholder_lastname}
            value={lastNameValue}
            onChange={value => {
              setLastName(value);
              lastnameOnBlurAction();
            }}
            onBlur={lastnameOnBlurAction}
            disabled={
              featureIsEnabled('disable-auto-complete-existing-user-invite')
                ? invitePending || userHasNamesSet
                : invitePending || userExists || userHasNamesSet
            }
          />

          {withSupervisedUser() ? (
            <div className={styles.generateUsernameSection}>
              {isPlacementTest ? (
                <>
                  <TextInput
                    id="username_text_input"
                    label={content.inputForm_username_label}
                    isValid={emailIsValid || username}
                    placeholder={content.inputForm_placeholder_email}
                    value={emailValue || username}
                    disabled={!!username}
                    onChange={email =>
                      setEmail(
                        email,
                        currentUserRole === userRoles.OUP_ADMIN && context === 'OUP_STAFF',
                        context,
                        userExists,
                        roleValue
                      )
                    }
                    onBlur={handleUsernameOnBlur}
                  />
                  <TextLink onClick={() => resetUsernameAction()}>
                    {content.enrol_user_add_to_class_input_clear_button}
                  </TextLink>
                </>
              ) : (
                <TextInputWithButton
                  id="username_text_input"
                  label={content.inputForm_username_label}
                  labelSub={content.inputForm_username_notice}
                  isValid={emailIsValid || username}
                  placeholder={content.inputForm_placeholder_email}
                  disabled={!!username}
                  value={emailValue || username}
                  onChange={email =>
                    setEmail(
                      email,
                      currentUserRole === userRoles.OUP_ADMIN && context === 'OUP_STAFF',
                      context,
                      userExists,
                      roleValue
                    )
                  }
                  onBlur={handleUsernameOnBlur}
                  {...getSupervisedUserButtonData()}
                />
              )}
              <p className={styles.usernameSupportLink}>
                <TextLink target="_blank" to={APP_CONSTANTS.HUB_SUPPORT_USERNAME_LINK}>
                  {content.inputForm_username_support_link}
                </TextLink>
              </p>
            </div>
          ) : (
            <TextInput
              id="email_text_input"
              label={content.inputForm_email_label}
              labelSub={content.inputForm_invitation_notice}
              isValid={emailIsInvalid !== null ? !emailIsInvalid : undefined}
              validationMessage={!(emailIsValid && !emailIsInvalid) ? emailErrorMessage : ''}
              placeholder={`name@${
                currentUserRole === userRoles.OUP_ADMIN &&
                context === 'OUP_STAFF' &&
                roleValue.length &&
                roleValue !== userRoles.EXTERNAL_CONTENT
                  ? 'oup'
                  : 'email'
              }.com`}
              value={emailValue || username}
              onChange={email =>
                setEmail(
                  email,
                  currentUserRole === userRoles.OUP_ADMIN && context === 'OUP_STAFF',
                  context,
                  userExists,
                  roleValue
                )
              }
              onBlur={emailOnBlurAction}
            />
          )}

          {isEltAssessmentMode() || hideAddToClassField ? null : renderAddToClassField()}

          {!withSupervisedUser() && (
            <Select
              isValid={roleIsValid}
              validationMessage={!roleIsValid ? content.enrol_staff_role_error_message : ''}
              value={roleValue}
              onChange={setRole}
              disabled={invitePending || userExists}
              label={content.inputForm_role_label}
              options={
                organisation.registrationStatus === ORG_REGISTRATION_STATUS.PARTIALLY_REGISTERED &&
                isOupStaff(currentUserRole)
                  ? [
                      ...getFormattedUserRoles(
                        getRolesAllowedToRegister(currentUserRole, context, orgRole, isOicMode())
                      )
                    ]
                  : [
                      {
                        value: '',
                        text: content.dropdown_selection_list_value
                      },
                      ...getFormattedUserRoles(
                        getRolesAllowedToRegister(
                          currentUserRole,
                          context,
                          orgRole,
                          isOicMode(),
                          currentOrganisationLti
                        )
                      )
                    ]
              }
            />
          )}

          {(isOrbMode() || orgRole === orgRoles.PRIMARY_SCHOOL) && (
            <div className="gin-top2">
              <TextLink to={administrationRightsSupportLink} target="_blank" role="button">
                {content.view_role_administration_rights}
              </TextLink>
            </div>
          )}

          <div className={styles.permissions}>{content.ensure_appropriate_permissions}</div>
        </div>
      </ScrollContainer>
    </form>
  );
}

EnrolUserInputting.propTypes = {
  closePanel: PropTypes.func.isRequired,
  organisationName: PropTypes.string.isRequired,
  currentUserRole: PropTypes.string.isRequired,
  canBulkUpload: PropTypes.bool.isRequired,
  context: PropTypes.string.isRequired,
  orgRole: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  // Bulk User Input Props
  bulkUserInputAction: PropTypes.func.isRequired,

  // Input values
  firstNameValue: PropTypes.string.isRequired,
  firstNameIsValid: PropTypes.bool,
  firstNameIsInvalid: PropTypes.bool,
  firstNameErrorMessage: PropTypes.string,
  lastNameValue: PropTypes.string.isRequired,
  lastNameIsValid: PropTypes.bool,
  lastNameIsInvalid: PropTypes.bool,
  lastNameErrorMessage: PropTypes.string,
  emailValue: PropTypes.string.isRequired,
  emailIsValid: PropTypes.bool,
  emailIsInvalid: PropTypes.bool,
  emailIsWaiting: PropTypes.bool,
  emailHasNotice: PropTypes.bool,
  invitePending: PropTypes.bool,
  userExists: PropTypes.bool,
  userHasNamesSet: PropTypes.bool,
  emailErrorMessage: PropTypes.string,
  roleValue: PropTypes.string.isRequired,
  roleIsValid: PropTypes.bool,
  roleIsInvalid: PropTypes.bool,
  enrolUserContext: PropTypes.string,
  username: PropTypes.string,

  // Validated input onBlurs
  firstnameOnBlurAction: PropTypes.func.isRequired,
  lastnameOnBlurAction: PropTypes.func.isRequired,
  emailOnBlurAction: PropTypes.func.isRequired,
  roleOnBlurAction: PropTypes.func.isRequired,

  // redux actions
  setFirstName: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setContext: PropTypes.func.isRequired,
  setRole: PropTypes.func.isRequired,
  resetRole: PropTypes.func.isRequired,
  addToClassViewAction: PropTypes.func.isRequired,
  removeClassAction: PropTypes.func.isRequired,
  generateUsernameAction: PropTypes.func.isRequired,
  clearGeneratedUsernameAction: PropTypes.func,
  usernameGenerated: PropTypes.bool,
  usernameGenerating: PropTypes.bool,

  // Submit action
  submitFormAction: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  selectedIds: PropTypes.array.isRequired,
  classrooms: PropTypes.object.isRequired,
  removeClassIdAction: PropTypes.func.isRequired,
  administrationRightsSupportLink: PropTypes.string.isRequired,

  currentOrganisationLti: PropTypes.bool,
  hideAddToClassField: PropTypes.bool,
  backAction: PropTypes.func,
  resetFormAction: PropTypes.func,
  resetUsernameAction: PropTypes.func,
  isPlacementTest: PropTypes.bool
};

export default compose(
  withLocalizedContent('enrolUserPanel', 'sharedTerms'),
  connect(
    (state, { orgId }) => ({
      orgId,
      organisation: get(state, ['organisations', 'data', state.organisationPage.orgId], {}),
      organisationName: get(state, ['organisations', 'data', state.organisationPage.orgId, 'name'], ''),
      currentUserRole: state.identity.role,
      canBulkUpload:
        state.organisations.data[orgId].type !== 'OUP_MASTER_GROUP' && !state.identity.currentOrganisationLti,
      orgRole: state.organisations.data[orgId].role,
      // Form inputs
      firstNameValue: state.enrolUser.firstNameValue,
      firstNameIsValid: state.enrolUser.firstNameIsValid,
      firstNameIsInvalid: state.enrolUser.firstNameIsInvalid,
      firstNameErrorMessage: state.enrolUser.firstNameErrorMessage,
      lastNameValue: state.enrolUser.lastNameValue,
      lastNameIsValid: state.enrolUser.lastNameIsValid,
      lastNameIsInvalid: state.enrolUser.lastNameIsInvalid,
      lastNameErrorMessage: state.enrolUser.lastNameErrorMessage,
      emailValue: state.enrolUser.emailValue,
      emailIsValid: state.enrolUser.emailIsValid,
      emailIsInvalid: state.enrolUser.emailIsInvalid,
      emailIsWaiting: state.enrolUser.emailIsWaiting,
      emailHasNotice: state.enrolUser.emailHasNotice,
      invitePending: state.enrolUser.invitePending,
      userExists: state.enrolUser.userExists,
      userHasNamesSet: state.enrolUser.userHasNamesSet,
      username: state.enrolUser.usernameWithoutOrg,
      usernameGenerated: state.enrolUser.usernameGenerated,
      usernameGenerating: state.enrolUser.usernameGenerating,

      emailErrorMessage: state.enrolUser.emailErrorMessage,
      roleValue: state.enrolUser.roleValue,
      roleIsValid: state.enrolUser.roleIsValid,
      roleIsInvalid: state.enrolUser.roleIsInvalid,

      classesAdded: state.enrolUser.classesAdded,
      enrolUserContext: state.enrolUser.context,
      classrooms: state.classrooms.data,
      selectedIds: state.organisationPage.selectedClassroomIds,

      currentOrganisationLti: state.identity.currentOrganisationLti
    }),
    {
      // Validated input onBlurs
      firstnameOnBlurAction: firstnameOnBlur,
      lastnameOnBlurAction: lastnameOnBlur,
      emailOnBlurAction: emailOnBlur,
      roleOnBlurAction: roleOnBlur,

      // onClick actions
      addToClassViewAction: addToClassView,
      removeClassAction: removeClass,
      removeClassIdAction: id => setClassroomSelected(id, false),

      // Input onChange actions
      setFirstName: setFirstNameValue,
      setLastName: setLastNameValue,
      setEmail: setEmailValue,
      setContext: setContextValue,
      setRole: setRoleValue,
      resetRole: resetRoleValues,
      generateUsernameAction: generateUsername,
      clearGeneratedUsernameAction: clearGeneratedUsername,

      // Bulk User Input Actions
      bulkUserInputAction: showBulkUserInput,
      // Submit button
      submitFormAction: submitForm,
      resetFormAction: resetForm,
      resetUsernameAction: resetUsername
    }
  )
)(EnrolUserInputting);
