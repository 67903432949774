import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Button from '@oup/shared-front-end/src/components/Button';
import user from '@oup/shared-node-browser/user.js';
import content from '../../utils/cmsContent';
import PopoutPanelIconHeading, { types as iconHeadingTypes } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import styles from '../PopoutPanelIconHeading/PopoutPanelIconHeading.scss';
import { inviteStates } from '../../redux/reducers/invitationsBanner.reducer.js';
import { userRoleFriendlyNames } from '../../globals/userRoles';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';

import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import { isHubMode, isOteMode } from '../../utils/platform.js';
import RegisterStep from '../../routes/Register/RegisterStep';
import { featureIsEnabled } from '../../globals/envSettings';

const userRoles = user.userRoles;
function InvitationActionResponse({ invites, orgJoined, orgId, continueFlow }) {
  const updated = (invites || []).find(invite => invite.state === inviteStates.UPDATED && invite.orgId === orgId);
  const updating = (invites || []).find(invite => invite.state === inviteStates.UPDATING && invite.orgId === orgId);
  const invite = updated || updating;
  const { orgName, roleName, requestFailed } = invite;
  const isExtRegFeature = featureIsEnabled('ote-extend-registration');

  // Helper to decide which success/partial/error heading+icon to show:
  const getIconHeading = () => {
    if (requestFailed) {
      // Invitation update FAILED
      return {
        title: content.invitationBanner.invitation_error_title_text,
        ...(isHubMode()
          ? {
              illustrationSrc: HubIllustrationConstants.ERROR,
              illustrationAltText: HubIllustrationAltText.ERROR
            }
          : { type: iconHeadingTypes.ERROR })
      };
    }

    if (orgJoined) {
      // Invitation updated SUCCESSFULLY :)
      return {
        subtitle: (content.invitationBanner.invitation_accept_success || '')
          .replace('{orgName}', orgName)
          .replace('{orgRole}', userRoleFriendlyNames(roleName, true)),
        ...(isHubMode()
          ? {
              illustrationSrc: HubIllustrationConstants.SUCCESS,
              illustrationAltText: HubIllustrationAltText.SUCCESS
            }
          : { type: iconHeadingTypes.VALID })
      };
    }

    return {
      subtitle: content.invitationBanner.request_complete || '',
      ...(isHubMode()
        ? {
            illustrationSrc: HubIllustrationConstants.STUDENT,
            illustrationAltText: HubIllustrationAltText.STUDENT
          }
        : { type: iconHeadingTypes.VALID })
    };
  };

  if (isOteMode() && isExtRegFeature && roleName === userRoles.LEARNER) {
    return (
      <div className={`grid ${styles.stepContainer}`}>
        <RegisterStep
          title={content.registrationPage.register_step2_title_text}
          subtitle={content.registrationPage.register_step2_subtitle_text}
          btnText={content.registrationPage.register_step_button_text}
          currentStep={2}
          btnAction={() => continueFlow(orgId, roleName)}
        />
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col">
        {isHubMode() ? (
          <PopoutPanelIllustrationHeading {...getIconHeading()} />
        ) : (
          <PopoutPanelIconHeading {...getIconHeading()} />
        )}
        <div className={`gin-top4 text-center ${styles.buttonsContainerCenter}`}>
          {/* // If success then defer to root IndexRedirectHandler to redirect to a role-appropriate page: */}
          <Button
            variant="filled"
            onClick={() => continueFlow(orgId, roleName)}
            text={content.invitationBanner.invitation_action_response_continue}
          />
        </div>
      </div>
    </div>
  );
}

InvitationActionResponse.propTypes = {
  continueFlow: PropTypes.func.isRequired,
  orgJoined: PropTypes.bool.isRequired,
  invites: PropTypes.array,
  orgId: PropTypes.string
};

export default connect(
  state => ({
    invites: state.invites.invites,
    orgId: state.invites.orgId
  }),
  {}
)(InvitationActionResponse);
