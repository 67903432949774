import * as t from '../actionTypes';

export const setPlacementTestSessionNameSuccess = data => ({
  type: t.ONBOARDING_WIZARD_SET_CREATE_PLACEMENT_TEST_SESSION_SUCCESS,
  payload: data
});
export const setPlacementTestSessionNameError = data => ({
  type: t.ONBOARDING_WIZARD_SET_CREATE_PLACEMENT_TEST_SESSION_ERROR,
  payload: data
});
export const createPlacementTestSessionRequest = data => ({
  type: t.ONBOARDING_WIZARD_CREATE_PLACEMENT_TEST_SESSION_REQUEST,
  payload: data
});
export const updatePlacementTestSessionRequest = data => ({
  type: t.ONBOARDING_WIZARD_UPDATE_PLACEMENT_TEST_SESSION_REQUEST,
  payload: data
});
export const updatePlacementTestSessionRequestSuccess = () => ({
  type: t.ONBOARDING_WIZARD_UPDATE_PLACEMENT_TEST_SESSION_REQUEST_SUCCESS
});
export const updatePlacementTestSessionRequestError = data => ({
  type: t.ONBOARDING_WIZARD_UPDATE_PLACEMENT_TEST_SESSION_REQUEST_ERROR,
  payload: data
});
export const setPlacemenetTestEnroledStudents = studentId => ({
  type: t.ONBOARDING_WIZARD_ENROLED_STUDENTS,
  payload: studentId
});
export const getPlacementTestSessionRequest = data => ({
  type: t.ONBOARDING_WIZARD_GET_PLACEMENT_TEST_SESSION_REQUEST,
  payload: data
});
export const getPlacementTestSessionRequestSuccess = data => ({
  type: t.ONBOARDING_WIZARD_GET_PLACEMENT_TEST_SESSION_REQUEST_SUCCESS,
  payload: data
});
export const getPlacementTestSessionRequestError = data => ({
  type: t.ONBOARDING_WIZARD_GET_PLACEMENT_TEST_SESSION_REQUEST_ERROR,
  payload: data
});
export const clearWizardState = () => ({
  type: t.ONBOARDING_WIZARD_CLEAR_STATE
});
