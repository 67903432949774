import { HubLayoutConstants } from '../../../globals/hubConstants';

export default function getPeopleActionButtons({
  hubContent,
  dropdownOptions: {
    isArchived = false,
    isPending = false,
    isActive = false,
    hideRemoveOption = false,
    showSendPasswordResetEmail = false,
    showSendBulkPasswordResetEmail = false,
    isSupervisedUser = false
  },
  dropdownActions: {
    removeUser = null,
    assignMaterial = null,
    bulkPasswordReset = null,
    sendInvitationReminder = null,
    sendPasswordResetEmail = null,
    regenerateSupervisedUserSignInCard = null
  },
  currentOrganisationLti = false
}) {
  const {
    PEOPLE_PANEL_TYPES: {
      ASSIGN_MATERIALS_TO_USERS,
      RESEND_INVITATION,
      REMOVE_MULTIPLE_USERS,
      REGENERATE_SUPERVISED_USER_SIGN_IN_CARD
    }
  } = HubLayoutConstants;

  if (isActive && !isArchived && !isPending) {
    if (currentOrganisationLti) {
      return [
        !hideRemoveOption
          ? {
              title: hubContent.remove_from_class_text,
              action: (e, data) => removeUser(e, REMOVE_MULTIPLE_USERS, data)
            }
          : {}
      ];
    }

    return [
      {
        title: hubContent.assign_learning_material,
        action: (e, data) => assignMaterial(e, ASSIGN_MATERIALS_TO_USERS, data)
      },
      !isSupervisedUser && showSendPasswordResetEmail
        ? {
            title: hubContent.send_password_reset_email,
            action: (e, data) => sendPasswordResetEmail(e, ASSIGN_MATERIALS_TO_USERS, data)
          }
        : {},
      isSupervisedUser
        ? {
            title: hubContent.create_sign_in_card,
            subtitle: hubContent.create_sign_in_card_label,
            action: (e, data) => regenerateSupervisedUserSignInCard(e, REGENERATE_SUPERVISED_USER_SIGN_IN_CARD, data)
          }
        : {},
      !hideRemoveOption
        ? {
            title: hubContent.remove_from_class_text,
            action: (e, data) => removeUser(e, REMOVE_MULTIPLE_USERS, data)
          }
        : {},
      showSendBulkPasswordResetEmail
        ? {
            title: hubContent.reset_password,
            action: (e, data) => bulkPasswordReset(e, null, data)
          }
        : {}
    ];
  }

  if (isPending && isActive) {
    return [
      {
        title: hubContent.send_invitation_reminder,
        action: (e, data) => sendInvitationReminder(e, RESEND_INVITATION, data)
      },
      {
        title: hubContent.assign_learning_material,
        action: (e, data) => assignMaterial(e, ASSIGN_MATERIALS_TO_USERS, data)
      },
      showSendPasswordResetEmail
        ? {
            title: hubContent.send_password_reset_email,
            action: sendPasswordResetEmail
          }
        : {},
      {
        title: hubContent.remove_from_class_text,
        action: (e, data) => removeUser(e, REMOVE_MULTIPLE_USERS, data)
      },
      showSendBulkPasswordResetEmail
        ? {
            title: hubContent.reset_password,
            action: (e, data) => bulkPasswordReset(e, null, data)
          }
        : {}
    ];
  }

  return [
    {
      title: hubContent.re_add_to_class_text,
      action: () => {}
    }
  ];
}
