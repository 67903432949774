import pick from 'lodash.pick';
import { put, select, take } from 'redux-saga/effects';
import {
  HUB_SUBMIT_FORM,
  submissionComplete,
  CLEAR_FORM
} from '../../../../reducers/hub/hubRemoveUsersFromClassroom.reducer';
import { getCurrentPlatform, isHubMode } from '../../../../../utils/platform';
import userRoles from '../../../../../globals/userRoles';
import { removeSelectedStudentIds, removeSelectedTeacherIds } from '../../../../reducers/classroomPage';

import {
  removeStudentsFromClassroom as removeStudentsFromClassroomComplete,
  removeTeachersFromClassroom as removeTeachersFromClassroomComplete
} from '../../../../reducers/data/classrooms';
import editClassroomApi from '../../../apiCalls/editClassroomApi';
import { pollClassStudentsRemoved } from '../dataRecency/pollClassStudents';
import { pollClassTeachersRemoved } from '../dataRecency/pollClassTeachers';
import { triggerLoadClassroomEdit } from './edit';
import pickStudentIdsByRole from './pickStudentIdsByRole';

export default function* removeMultipleUsersFromClassroom() {
  console.log('[removeMultipleUsersFromClassroom Saga] Beginning');

  while (true) {
    console.log('[removeMultipleUsersFromClassroom Saga] Waiting for user to submit form');
    yield take(HUB_SUBMIT_FORM);

    console.log('[removeMultipleUsersFromClassroom Saga] Form submitted. capturing data from state...');
    const { organisationId, classroomId, classroomName, selectedUsers, notifyWithEmail, people } = yield select(
      state => ({
        organisationId: state.classroomPage.orgId,
        classroomId: state.classroomPage.classroomId,
        classroomName: state.classrooms.data[state.classroomPage.classroomId].name,
        selectedUsers: state.hubRemoveUsersFromClassroom.submittedIds,
        notifyWithEmail: state.hubRemoveUsersFromClassroom.notifyWithEmail,
        people: state.people.data
      })
    );

    console.log(
      `[removeMultipleUsersFromClassroom Saga] Submitting request to remove student IDs: ${selectedUsers} from class with ID: ${classroomId}`
    );
    const selectedUserDetails = pick(people, selectedUsers);

    const managedUsersArchived = pickStudentIdsByRole(selectedUserDetails, userRoles.MANAGED_USER);
    const studentsRemoved = pickStudentIdsByRole(selectedUserDetails, userRoles.LEARNER);
    const teachersRemoved = pickStudentIdsByRole(selectedUserDetails, userRoles.TEACHER);
    const response = yield editClassroomApi(organisationId, classroomId, {
      name: classroomName,
      ...(managedUsersArchived.length ? { managedUsersArchived } : {}),
      ...(teachersRemoved.length ? { teachersRemoved } : {}),
      ...(studentsRemoved.length ? { studentsRemoved } : {}),
      platformCode: getCurrentPlatform(),
      notifyWithEmail: isHubMode() ? false : notifyWithEmail
    });

    const editResult = response || {};
    const requestFailed = editResult.status !== 'success';
    const failedIds = (editResult.data || {}).failedIds || [];

    console.log('[removeMultipleUsersFromClassroom Saga] Edit Class request complete, showing confirmation page');
    yield put(submissionComplete(requestFailed, requestFailed ? selectedUsers : failedIds));

    if (!requestFailed) {
      const successfullyRemovedUserIds = selectedUsers.filter(id => !failedIds.includes(id));
      yield put(removeTeachersFromClassroomComplete(classroomId, successfullyRemovedUserIds));
      yield put(removeSelectedTeacherIds(successfullyRemovedUserIds));
      yield pollClassTeachersRemoved(successfullyRemovedUserIds);
      yield put(removeStudentsFromClassroomComplete(classroomId, successfullyRemovedUserIds));
      yield put(removeSelectedStudentIds(successfullyRemovedUserIds));
      yield pollClassStudentsRemoved(successfullyRemovedUserIds);
    }

    yield triggerLoadClassroomEdit();

    yield take(CLEAR_FORM);
    console.log('[removeMultipleUsersFromClassroom Saga] Form closed, resetting state and saga');
  }
}
