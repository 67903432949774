import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import Button, { buttonTypes } from '../../components/Button/Button.js';
import TextInput from '../../components/TextInput/TextInput.js';
import { getIdpLoginUrl } from '../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import styles from './RedeemCodeSuccess.scss';
import CardWithThumbnail from '../../components/CardWithThumbnail/CardWithThumbnail.js';
import colors from '../../globals/colors.js';
import { HubLayoutConstants } from '../../globals/hubConstants';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import { isHubMode, getCurrentPlatform, isMflMode } from '../../utils/platform';
import Validation from '../../components/Validation/Validation.js';
import { socialIdpIsEnabled, featureIsEnabled } from '../../globals/envSettings';
import Link from '../../components/Link/Link.js';
import { resetActivationCode, setGuestEmailId, validateGuestEmailId } from '../../redux/actions/activationCode';

class RedeemCodeSuccessPage extends Component {
  static handleRegister = async (e, redeemCodeSuccessPage) => {
    e.preventDefault();
    const registerPath = socialIdpIsEnabled(getCurrentPlatform()) ? '/register-choice' : '/register';
    const url = isHubMode()
      ? `${registerPath}?returnUrl=${window.location.host}${HubLayoutConstants.PATH_NAMES.DASHBOARD_REDEEM_PATH}`
      : `${registerPath}?returnUrl=${redeemCodeSuccessPage.returnUrl.replace('https://', '')}`;
    window.location.href = url;
    return false;
  };

  componentDidMount() {}

  handleSignIn = async () => {
    const { platform } = this.props;
    const url = await getIdpLoginUrl(null, { platform });
    window.location.href = url;
  };

  render() {
    const {
      redeemCodeValue,
      guestEmailId,
      localizedContent: { redeemCodeSuccessPage },
      history,
      resetActivationCodeAction,
      setGuestEmailIdAction,
      validateGuestEmailIdAction,
      guestEmailIdValueIsValid,
      guestEmailIdValueIsError
    } = this.props;

    const queryString = window.location.search;
    const redeemLink = queryString ? `/redeem${queryString}` : '/redeem';

    if (!redeemCodeValue) history.push(redeemLink);
    return redeemCodeValue ? (
      <div>
        <Helmet title={redeemCodeSuccessPage.page_title} />
        <form>
          <div className="grid">
            <div className="bg">
              <div className="grid">
                <div className={`row ${styles.row}`}>
                  <div className={`col sm7 md6 lg5 ${styles.redeemCodeSuccessPage}`}>
                    <CardWithThumbnail thumbnailColor={colors.PRIMARY_BLUE_HOVER} thumbnailGlyph={GLYPHS.ICON_USER}>
                      <div className={styles.validRedeemCode}>
                        <div className={styles.content}>
                          <h1>
                            {redeemCodeValue}&nbsp;&nbsp;
                            <SVGIcon className={styles.SVGIcon} glyph={GLYPHS.ICON_CHECK_CIRCLE} />
                          </h1>
                          <Link to={redeemLink} className={styles.registerLink} onClick={resetActivationCodeAction}>
                            {redeemCodeSuccessPage.change_code}
                          </Link>
                        </div>
                      </div>
                      <hr />
                      {isMflMode() && featureIsEnabled('mfl-features-guest-download') ? (
                        <div className={styles.emailContent}>
                          <p className={styles.email_label}>{redeemCodeSuccessPage.enter_email_address}</p>
                          <Validation
                            forId="enterEmailId"
                            isValid={guestEmailIdValueIsValid}
                            isError={guestEmailIdValueIsError}
                            rightHandInput="true"
                            message={guestEmailIdValueIsError && redeemCodeSuccessPage.enter_valid_email}
                            noInputErrorTopSpacing
                            noLabelAboveField="false"
                          >
                            <TextInput
                              id="enterEmailId"
                              value={guestEmailId}
                              onChange={value => {
                                setGuestEmailIdAction(value);
                              }}
                              onBlur={() => {
                                validateGuestEmailIdAction();
                              }}
                            />
                          </Validation>
                          <p
                            className={styles.email_info}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: redeemCodeSuccessPage.email_info }}
                          />
                          <Button
                            id="redeemCodeButton"
                            type={buttonTypes.PRIMARY}
                            text={redeemCodeSuccessPage.submit_button_text}
                            disabled={!guestEmailIdValueIsValid}
                          />
                        </div>
                      ) : (
                        <>
                          <div className={styles.linkItem}>
                            <p>{redeemCodeSuccessPage.sign_in_text}</p>
                            <div className={styles.content}>
                              <Button
                                id="signin-button"
                                type={buttonTypes.PRIMARY}
                                text={redeemCodeSuccessPage.sign_in_button_text}
                                disableExternalBehaviour
                                onClick={this.handleSignIn}
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className={styles.linkItems}>
                            <div className={styles.content}>
                              <p>
                                {`${redeemCodeSuccessPage.sign_up_text} `}
                                <Link
                                  underlined
                                  active
                                  className={styles.registerLink}
                                  onClick={e => RedeemCodeSuccessPage.handleRegister(e, redeemCodeSuccessPage)}
                                  disabled={false}
                                >
                                  {redeemCodeSuccessPage.sign_up_button_text}
                                </Link>
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </CardWithThumbnail>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    ) : null;
  }
}

RedeemCodeSuccessPage.propTypes = {
  platform: PropTypes.string,
  redeemCodeValue: PropTypes.string.isRequired,
  guestEmailId: PropTypes.string,
  guestEmailIdValueIsValid: PropTypes.bool,
  guestEmailIdValueIsError: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  resetActivationCodeAction: PropTypes.func,
  setGuestEmailIdAction: PropTypes.func,
  validateGuestEmailIdAction: PropTypes.func
};

export default compose(
  withLocalizedContent('redeemCodeSuccessPage'),
  connect(
    state => ({
      redeemCodeValue: state.activationCode.redeemCodeValue,
      guestEmailId: state.activationCode.guestEmailId,
      guestEmailIdValueIsValid: state.activationCode.guestEmailIdValueIsValid,
      guestEmailIdValueIsError: state.activationCode.guestEmailIdValueIsError
    }),
    {
      resetActivationCodeAction: resetActivationCode,
      setGuestEmailIdAction: setGuestEmailId,
      validateGuestEmailIdAction: validateGuestEmailId
    }
  )
)(withRouter(RedeemCodeSuccessPage));
