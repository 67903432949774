import PropTypes from 'prop-types';
import React from 'react';
import Media from 'react-media';
import classnames from 'classnames';
import moment from 'moment';
import Button from '@oup/shared-front-end/src/components/Button';
import Breadcrumbs, { breadcrumbPathsFromHierarchy, pathnameUpNLevels } from '../Breadcrumbs/Breadcrumbs';
import breakpoints from '../../styles/constants/_breakpoints.scss';
import ErrorStrip from '../ErrorStrip/ErrorStrip';
import GradebookCard from '../GradebookCard/GradebookCard';
import GradebookTable from '../GradebookTable/GradebookTable';
import DataRefresher from '../DataRefresher/DataRefresher';
import RecordListingControls from '../GradebookTable/RecordListingControls';
import { OPTIONS as SPACING_OPTIONS } from '../SpacingOptions/SpacingOptions';
import AverageBanner from './AverageBanner';
import AverageRow from './AverageRow';
import AverageRowIndex from './AverageRowIndex';
import AverageTotals from './AverageTotals';
import styles from './ClassProgress.scss';
import Headers from './Headers';
import LevelJumpToggle from './LevelJumpToggle';
import Levels, { getLevelData } from './Levels';
import RecordTotals, { getRecordWithTotals } from './RecordTotals';
import SubSectionSkeletonLoader from '../SkeletonLoader/SubSectionSkeletonLoader';
import SortRecords, { getSortedRecords } from './SortRecords';
import StudentRow, { getFormattedAttemptsLevels, getLevelCompletionActivities } from './StudentRow';
import SubHeaders from './SubHeaders';
import withLocalizedContent from '../../language/withLocalizedContent';
import downloadAsFile from '../../utils/downloadAsFile';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import { featureIsEnabled } from '../../globals/envSettings';
import { attemptOtions } from '../LearnerProgress/LearnerProgress';
import { replaceCommaWithSpace, removeHtmlTags } from '../../utils/string';
import { isHubMode } from '../../utils/platform';

class ClassProgress extends React.Component {
  static _immutableArrayInsert = (array, index, elem) => {
    const clone = [...array];
    clone.splice(index, 0, elem);
    return clone;
  };

  static _generateClassData = (
    recordsWithTotals,
    sortKey,
    sortDirection,
    isActivityLevel,
    hierarchyData,
    classTeacherNames,
    useCompletedOnly
  ) => {
    const classData = getSortedRecords(recordsWithTotals, sortKey, sortDirection, isActivityLevel);
    let classTeacherIndex = 1;
    const classDataArrayWithPercentages = [];
    const classDataArrayWithoutPercentages = [];
    classData.forEach(value => {
      const { totalAttemptsFormatted, totalLevelsFormatted } = getFormattedAttemptsLevels(value, true);
      const {
        totalAttemptsFormatted: totalAttemptsFormattedWithoutPercentages,
        totalLevelsFormatted: totalLevelsFormattedWithoutPercentages
      } = getFormattedAttemptsLevels(value, false);

      let rowData = [
        classTeacherIndex < classTeacherNames.length ? replaceCommaWithSpace(classTeacherNames[classTeacherIndex]) : ''
      ];
      let rowDataWithoutPercentages = [
        classTeacherIndex < classTeacherNames.length ? replaceCommaWithSpace(classTeacherNames[classTeacherIndex]) : ''
      ];
      classTeacherIndex += 1;
      if (hierarchyData.length > 0) {
        for (let index = 0; index < hierarchyData.length; index += 1) {
          rowData.push('');
          rowDataWithoutPercentages.push('');
        }
      }
      rowData = [
        ...rowData,
        ...[
          replaceCommaWithSpace(value.studentName),
          value.lastAccessed ? moment(value.lastAccessed).format('DD MMM YYYY') : 'Never',
          totalAttemptsFormatted,
          totalLevelsFormatted
        ]
      ];

      rowDataWithoutPercentages = [
        ...rowDataWithoutPercentages,
        ...[
          replaceCommaWithSpace(value.studentName),
          value.lastAccessed ? moment(value.lastAccessed).format('DD MMM YYYY') : 'Never',
          totalAttemptsFormattedWithoutPercentages,
          totalLevelsFormattedWithoutPercentages
        ]
      ];

      value.levels.forEach(element => {
        const { levelCompletionSlashAttempts, levelSlashActivityScore } = getLevelCompletionActivities(
          element,
          value.isActivityLevel,
          true,
          0,
          false,
          '',
          useCompletedOnly
        );

        rowData.push(levelCompletionSlashAttempts);
        rowData.push(levelSlashActivityScore);

        const {
          levelCompletionSlashAttempts: levelCompletionSlashAttemptsWithoutPercentages,
          levelSlashActivityScore: levelSlashActivityScoreWithoutPercentages
        } = getLevelCompletionActivities(element, value.isActivityLevel, false, 0, false, '', useCompletedOnly);

        rowDataWithoutPercentages.push(levelCompletionSlashAttemptsWithoutPercentages);
        rowDataWithoutPercentages.push(levelSlashActivityScoreWithoutPercentages);
      });

      classDataArrayWithPercentages.push(rowData);
      classDataArrayWithoutPercentages.push(rowDataWithoutPercentages);
    });
    return [classDataArrayWithPercentages, classDataArrayWithoutPercentages];
  };

  static _isClassPendingMark = records => {
    for (let i = 0; i < records.length; i += 1) {
      if (records[i].levels && records[i].levels.length) {
        const levels = records[i].levels;
        for (let j = 0; j < levels.length; j += 1) {
          if (levels[j].pendingMark) {
            return true;
          }
        }
      }
    }
    return false;
  };

  static pendingMarksPerUnit = records => {
    const pendingMarks = new Array(records[0]?.levels?.length).fill(false);
    for (let i = 0; i < records.length; i += 1) {
      if (records[i].levels && records[i].levels.length) {
        const levels = records[i].levels;
        for (let j = 0; j < levels.length; j += 1) {
          if (levels[j].pendingMark && !pendingMarks[j]) {
            pendingMarks[j] = true;
          }
        }
      }
    }

    return pendingMarks;
  };

  constructor(props) {
    super(props);

    this.state = {
      fileName: '',
      fileType: 'csv',
      generateCSV: false,
      averageData: []
    };
  }

  componentDidUpdate(prevProps) {
    const { records, classTeachersLoading } = this.props;
    const { generateCSV } = this.state;

    if (prevProps.records !== records) {
      this.updateClassAverage();
    }

    if (classTeachersLoading === false && generateCSV === true) {
      this._generateCSV();
    }
  }

  _exportToCSV = (fileName, fileType = 'csv') => {
    const { params, initialiseSearch } = this.props;

    initialiseSearch('classTeachers', true, {
      classId: params.classroomId,
      orgId: params.orgId,
      roles: ['TEACHER'],
      active: true
    });

    this.setState({
      fileName,
      fileType,
      generateCSV: true
    });
  };

  _generateClassAverage = (classData, classTeacherNames, hierarchyData, levelData) => {
    const sumOfTotalMarksScored = [];
    const sumOfTotalMarksAvailable = [];
    const sumOfPercentages = [];
    const average = [];
    const dividedBy = [];
    const indexWithoutPercentStartValue = hierarchyData.length + 5; // ['Teacher', 'Student', 'Date', 'Completed', 'Score']
    const indexWithoutPercentIncrementValue = 2;
    const indexWithoutPercentValues = [];
    let indexWithoutPercentPointer = indexWithoutPercentStartValue;
    if (levelData.isActivityLevel) {
      levelData.levels.forEach((value, index) => {
        if (index !== 0) {
          indexWithoutPercentPointer += indexWithoutPercentIncrementValue;
        }
        indexWithoutPercentValues.push(indexWithoutPercentPointer);
      });
    }

    classData.forEach(studentArray => {
      studentArray.forEach((studentData, index) => {
        average[index] = average[index] ? average[index] : 0;
        if (featureIsEnabled('gradebook-averages-calculation')) {
          // See EVS-698 for reference
          sumOfTotalMarksScored[index] = sumOfTotalMarksScored[index] ? sumOfTotalMarksScored[index] : 0;
          sumOfTotalMarksAvailable[index] = sumOfTotalMarksAvailable[index] ? sumOfTotalMarksAvailable[index] : 0;

          if (studentData.toString().includes('/')) {
            // studentData is a string that looks like x/y
            // where x is the number of completed activities (completed column) or the marked scored (score column)
            // and y is the total number of activities (completed column) or the total marks available (score column)
            const dataValues = studentData.split('/');
            // sumed up all total marked scored
            sumOfTotalMarksScored[index] += Number(dataValues[0]);
            // sumed up total scores available
            sumOfTotalMarksAvailable[index] += Number(dataValues[1]);
          } else if (levelData.isActivityLevel && indexWithoutPercentValues.includes(index)) {
            // we need to count the attempts
            sumOfTotalMarksScored[index] += Number(studentData);
            sumOfTotalMarksAvailable[index] += 1;
          }
        } else {
          // See EVS-447 for reference
          sumOfPercentages[index] = sumOfPercentages[index] ? sumOfPercentages[index] : 0;
          dividedBy[index] = dividedBy[index] ? dividedBy[index] : 0;
          // studentData is a string that looks like x%
          if (studentData.toString().charAt(studentData.length - 1) === '%' || !Number.isNaN(studentData)) {
            dividedBy[index] += 1;
            // sumed up all the percentages
            sumOfPercentages[index] += !Number.isNaN(studentData)
              ? studentData
              : Number(studentData.toString().slice(0, -1));
          }
        }
      });
    });

    if (featureIsEnabled('gradebook-averages-calculation')) {
      sumOfTotalMarksScored.forEach((sum, index) => {
        average[index] =
          sum === 0 || sumOfTotalMarksAvailable[index] === 0
            ? '0%'
            : `${Number((sum / sumOfTotalMarksAvailable[index]) * 100).toFixed(1)}%`;
        if (indexWithoutPercentValues.includes(index)) {
          // average of attempts
          average[index] = Number(sum / sumOfTotalMarksAvailable[index]).toFixed(1);
        }
      });
    } else {
      sumOfPercentages.forEach((sum, index) => {
        if (dividedBy[index] > 0) {
          average[index] = `${(Math.round((sum / dividedBy[index]) * 10) / 10).toFixed(1)}%`;
        } else {
          average[index] = `${sum.toFixed(1)}%`;
        }
        if (indexWithoutPercentValues.includes(index)) {
          average[index] = average[index].replace('%', '');
        }
      });
    }

    average.splice(0, hierarchyData.length + 3);

    let classTeacherIndex = 0;
    let classAverageData = [
      classTeacherIndex < classTeacherNames.length ? replaceCommaWithSpace(classTeacherNames[classTeacherIndex]) : ''
    ];
    classTeacherIndex += 1;

    if (hierarchyData.length > 0) {
      for (let index = 0; index < hierarchyData.length; index += 1) {
        classAverageData.push(replaceCommaWithSpace(hierarchyData[index].text));
      }
    }

    classAverageData = [...classAverageData, ...['Class Average', '-'], ...average];

    this.setState({ averageData: average });

    return classAverageData;
  };

  _generateCSV = () => {
    const {
      records,
      usePercentages,
      sortKey,
      sortDirection,
      hierarchy,
      productTitle,
      useCompletedOnly,
      classTeachers
    } = this.props;

    const { fileName, fileType } = this.state;

    const classTeacherNames = classTeachers.map(classTeacher => `${classTeacher.firstname} ${classTeacher.lastname}`);
    const hierarchyData = breadcrumbPathsFromHierarchy(hierarchy, productTitle);
    const levelData = getLevelData(records);
    const recordsWithTotals = getRecordWithTotals(records, useCompletedOnly);

    let heading = [''];
    let subHeading = ['Teacher'];

    if (hierarchyData.length > 0) {
      const lmHeads = ['Learning Material', 'Unit', 'Section'];
      for (let index = 0; index < hierarchyData.length; index += 1) {
        heading.push('');
        subHeading.push(lmHeads[index]);
      }
    }
    heading = [
      ...heading,
      ...[
        '',
        featureIsEnabled('replacing-last-accessed-with-last-opened') ? 'Last Opened' : 'Last Accessed',
        'Total',
        ''
      ]
    ];
    subHeading = [...subHeading, ...['Student', 'Date', 'Completed', 'Score']];

    levelData.levels.forEach(value => {
      heading.push(`"${removeHtmlTags(replaceCommaWithSpace(value.name))}"`);
      heading.push('');
      subHeading.push(levelData.isActivityLevel ? 'Attempts' : 'Completed');
      subHeading.push('Score');
    });

    const totalClassData = ClassProgress._generateClassData(
      recordsWithTotals,
      sortKey,
      sortDirection,
      levelData.isActivityLevel,
      hierarchyData,
      classTeacherNames,
      useCompletedOnly
    );
    const classAverageData = this._generateClassAverage(
      featureIsEnabled('gradebook-averages-calculation') ? totalClassData[1] : totalClassData[0],
      classTeacherNames,
      hierarchyData,
      levelData
    );

    const csvData = ['SEP=,', heading.join(','), subHeading.join(',')];
    csvData.push(classAverageData.join(','));
    const classData = usePercentages ? totalClassData[0] : totalClassData[1];
    classData.forEach(rowData => {
      rowData.forEach((value, index) => {
        if (value.toString().includes('/')) {
          rowData[index] = ` ${rowData[index]}`;
        }
      });
      csvData.push(rowData.join(','));
    });

    // Download CSV file
    downloadAsFile(csvData.join('\n'), `${fileName}.${fileType}`, 'text/csv');

    this.setState({
      fileName: '',
      fileType: 'csv',
      generateCSV: false
    });
  };

  updateClassAverage() {
    const { records, sortKey, sortDirection, useCompletedOnly, hierarchy, productTitle, classTeachers } = this.props;

    const classTeacherNames = classTeachers.map(classTeacher => `${classTeacher.firstname} ${classTeacher.lastname}`);

    const hierarchyData = breadcrumbPathsFromHierarchy(hierarchy, productTitle);
    const levelData = getLevelData(records);
    const recordsWithTotals = getRecordWithTotals(records, useCompletedOnly);

    const totalClassData = ClassProgress._generateClassData(
      recordsWithTotals,
      sortKey,
      sortDirection,
      levelData.isActivityLevel,
      hierarchyData,
      classTeacherNames,
      useCompletedOnly
    );
    this._generateClassAverage(
      featureIsEnabled('gradebook-averages-calculation') ? totalClassData[1] : totalClassData[0],
      classTeacherNames,
      hierarchyData,
      levelData
    );
  }

  render() {
    const {
      loadGradebookClass,
      loadProductLocks,
      records,
      sortKey,
      sortDirection,
      sortOnChange,
      tableSpacing,
      usePercentages,
      useCompletedOnly,
      onUsePercentagesOnChange,
      onUseCompletedOnlyOnChange,
      rangeValue,
      rangeValueOnchange,
      useFilterByScore,
      onUseFilterByScoreOnChange,
      attemptFilter,
      attemptFilterOnChange,
      isLoading,
      failure,
      classTitle,
      hierarchy,
      productTitle,
      params,
      products,
      localizedContent: { classProgress: localizedContent },
      role,
      productType
    } = this.props;
    const { averageData } = this.state;
    const shouldDisableAnswerView =
      (featureIsEnabled('gradebook-first-and-last-answer') &&
        (attemptFilter === 'best' || attemptFilter === 'average')) ||
      (productType === 'onlinepractice' && attemptFilter !== 'latest');

    return (
      <div className="gin-top1">
        <RecordListingControls
          usePercentages={usePercentages}
          useCompletedOnly={useCompletedOnly}
          onUsePercentagesOnChange={onUsePercentagesOnChange}
          onUseCompletedOnlyOnChange={onUseCompletedOnlyOnChange}
          attemptFilter={attemptFilter}
          attemptFilterOptions={Object.values(
            attemptOtions(localizedContent, featureIsEnabled('olb-gradebook-score-all-activities'))
          )}
          onAttemptFilterOnChange={attemptFilterOnChange}
          localizedContent={localizedContent}
          rangeValue={rangeValue}
          rangeValueOnchange={rangeValueOnchange}
          useFilterByScore={useFilterByScore}
          onUseFilterByScoreOnChange={onUseFilterByScoreOnChange}
          productType={productType}
        />
        <div className="grid">
          <div className="row">
            <div className="col">
              {!isLoading ? (
                <Breadcrumbs
                  paths={[
                    {
                      pathname: pathnameUpNLevels(2),
                      text: localizedContent.button_back_to_text
                    },
                    ...breadcrumbPathsFromHierarchy(hierarchy, productTitle)
                  ]}
                />
              ) : null}

              <div className={styles.ClassProgress__header}>
                <h1 className={styles.ClassProgress__title}>{productTitle}</h1>
                <div className={classnames('pad2', styles.ClassProgress__export_button)}>
                  <Button
                    variant="filled"
                    icon={{ component: <SVGIcon glyph={GLYPHS.ICON_DOWNLOAD} /> }}
                    text={localizedContent.button_gradebook_export_label}
                    onClick={() => this._exportToCSV(`${classTitle}_${moment().format('DDMMMYYYY')}`)}
                  />
                </div>
              </div>
              {isHubMode() && (
                <DataRefresher
                  loading={isLoading}
                  noSidePadding
                  showLabel={false}
                  refreshData={() => {
                    loadGradebookClass();
                    loadProductLocks();
                  }}
                />
              )}
              {isLoading ? (
                <SubSectionSkeletonLoader panelName={params.panelName} tabName={params.tabName} />
              ) : (
                <Levels records={records}>
                  {({ levels, isActivityLevel }) => (
                    <RecordTotals records={records} useCompletedOnly={useCompletedOnly}>
                      {recordsWithTotals => (
                        <AverageTotals levels={levels} useCompletedOnly={useCompletedOnly}>
                          {averageTotals => (
                            <Media query={JSON.parse(breakpoints.smMedia)}>
                              {matches => {
                                if (matches) {
                                  return (
                                    <GradebookTable stickyColumn spacing={tableSpacing}>
                                      <GradebookTable.Head>
                                        <Headers levels={levels} localizedContent={localizedContent} />
                                        <SubHeaders
                                          levels={levels}
                                          sortKey={sortKey}
                                          sortDirection={sortDirection}
                                          sortOnChange={sortOnChange}
                                          isActivityLevel={isActivityLevel}
                                        />
                                      </GradebookTable.Head>
                                      <SortRecords
                                        records={recordsWithTotals}
                                        sortKey={sortKey}
                                        direction={sortDirection}
                                        isActivityLevel={isActivityLevel}
                                      >
                                        {sortedRecords => (
                                          <AverageRowIndex
                                            recordsWithTotals={recordsWithTotals}
                                            levels={levels}
                                            averageTotals={averageTotals}
                                            sortKey={sortKey}
                                            sortDirection={sortDirection}
                                            isActivityLevel={isActivityLevel}
                                          >
                                            {averageRowIndex => (
                                              <GradebookTable.Body>
                                                {ClassProgress._immutableArrayInsert(
                                                  sortedRecords.map((record, i) => (
                                                    <StudentRow
                                                      products={products}
                                                      recordWithTotals={record}
                                                      usePercentages={usePercentages}
                                                      useCompletedOnly={useCompletedOnly}
                                                      key={i}
                                                      rangeValue={rangeValue}
                                                      useFilterByScore={useFilterByScore}
                                                      params={params}
                                                      shouldDisableAnswerView={shouldDisableAnswerView}
                                                      attemptFilter={attemptFilter}
                                                      tooltipText={
                                                        productType === 'onlinepractice'
                                                          ? localizedContent.vst_tooltip_text
                                                          : localizedContent.tooltip_text
                                                      }
                                                      productType={productType}
                                                    />
                                                  )),
                                                  averageRowIndex,
                                                  <AverageRow
                                                    key="averageRow"
                                                    classAverageData={averageData}
                                                    pendingMarks={ClassProgress.pendingMarksPerUnit(records)}
                                                    levels={levels}
                                                  />
                                                )}
                                              </GradebookTable.Body>
                                            )}
                                          </AverageRowIndex>
                                        )}
                                      </SortRecords>
                                      <GradebookTable.Foot>
                                        <SubHeaders
                                          levels={levels}
                                          sortKey={sortKey}
                                          sortDirection={sortDirection}
                                          sortOnChange={sortOnChange}
                                        />
                                        <Headers levels={levels} localizedContent={localizedContent} />
                                      </GradebookTable.Foot>
                                    </GradebookTable>
                                  );
                                }
                                const isPendingMark = ClassProgress._isClassPendingMark(records);
                                return (
                                  <div>
                                    {!isActivityLevel ? (
                                      <LevelJumpToggle levels={levels} hierarchy={hierarchy} />
                                    ) : null}
                                    <AverageBanner
                                      averageTotals={averageTotals}
                                      pendingMark={isPendingMark}
                                      usePercentages={
                                        featureIsEnabled('olb-gradebook-score-all-activities') || usePercentages
                                      }
                                      isActivityLevel={isActivityLevel}
                                      levels={levels}
                                      recordsLength={records.length}
                                      localizedContent={localizedContent}
                                    />
                                    {recordsWithTotals.map(recordWithTotals => (
                                      <GradebookCard
                                        role={role}
                                        key={recordWithTotals.id}
                                        recordWithTotals={recordWithTotals}
                                        usePercentages={usePercentages}
                                        isActivityLevel={isActivityLevel}
                                        classTitle={classTitle}
                                        rangeValue={rangeValue}
                                        useFilterByScore={useFilterByScore}
                                        useCompletedOnly={useCompletedOnly}
                                        localizedContent={localizedContent}
                                        pendingMark={isPendingMark}
                                        params={params}
                                        shouldDisableAnswerView={
                                          featureIsEnabled('gradebook-first-and-last-answer') &&
                                          (attemptFilter === 'best' || attemptFilter === 'average')
                                        }
                                        tooltipText={localizedContent.tooltip_text}
                                        productType={productType}
                                      />
                                    ))}
                                    <LevelJumpToggle levels={levels} hierarchy={hierarchy} />
                                  </div>
                                );
                              }}
                            </Media>
                          )}
                        </AverageTotals>
                      )}
                    </RecordTotals>
                  )}
                </Levels>
              )}
              {failure ? <ErrorStrip /> : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ClassProgress.propTypes = {
  records: PropTypes.array,
  sortKey: PropTypes.string,
  sortDirection: PropTypes.string,
  sortOnChange: PropTypes.func,
  tableSpacing: PropTypes.oneOf(Object.values(SPACING_OPTIONS)),
  classTitle: PropTypes.string,
  hierarchy: PropTypes.string,
  isLoading: PropTypes.bool,
  failure: PropTypes.bool,
  loadGradebookClass: PropTypes.func,
  loadProductLocks: PropTypes.func,
  productTitle: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  ...RecordListingControls.propTypes,
  initialiseSearch: PropTypes.func,
  classTeachers: PropTypes.array,
  classTeachersLoading: PropTypes.bool,
  products: PropTypes.object,
  role: PropTypes.string,
  productType: PropTypes.string
};

ClassProgress.defaultProps = {
  records: [],
  tableSpacing: SPACING_OPTIONS.MEDIUM,
  classTitle: 'Class'
};

export default withLocalizedContent('classProgress')(ClassProgress);
