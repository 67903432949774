import { put, select, take, all, takeLatest } from 'redux-saga/effects';
import isEmpty from 'lodash.isempty';
import { setClassroomsToArchive } from '../../../../reducers/classroomArchive';
import {
  CLOSE_FORM,
  EDIT_CLASS,
  GOTO_ARCHIVE,
  loadClassroomEdit,
  setClassroomNameValidated,
  setIdsForSubmission,
  SET_CLASSROOM_NAME,
  SET_CLASS_CODE,
  showConfirmation,
  setClassroomCodeValidated
} from '../../../../reducers/classroomEdit';
import { removeSelectedStudentIds, removeSelectedTeacherIds } from '../../../../reducers/classroomPage';
import {
  addStudentsToClassroom,
  addTeachersToClassroom,
  CLASS_DETAILS_LOADED,
  removeStudentsFromClassroom,
  removeTeachersFromClassroom,
  updateClassroomLimit,
  updateClassroomName,
  updateClassJoiningCode
} from '../../../../reducers/data/classrooms';
import checkClassNameApi from '../../../apiCalls/classes/checkClassName.api';
import validateGroupJoiningCodeApi from '../../../apiCalls/validateGroupJoiningCodeApi';
import editClassroomApi from '../../../apiCalls/editClassroomApi';
import editJoiningCodeApi from '../../../apiCalls/editJoiningCodeApi';
import { pollClassStudentsAdded, pollClassStudentsRemoved } from '../dataRecency/pollClassStudents';
import { pollClassTeachersAdded, pollClassTeachersRemoved } from '../dataRecency/pollClassTeachers';
import { orgRoles } from '../../../../../globals/orgRoles';
import { getCurrentPlatform, isHubMode } from '../../../../../utils/platform';
/**
 * This handles calls to the checkClassName endpoint
 */
function* validateClassname(action) {
  const classroomName = action.classroomName.trim().replace(/\s+/g, ' ');

  // Also need current orgId
  const orgId = yield select(state => state.classroomPage.orgId);

  // Only start validating once the input's length is greater than zero
  if (classroomName.length > 0) {
    const response = yield checkClassNameApi(orgId, classroomName);
    const failed = !response || !response.data || response.data.exists === null;
    const exists = response && response.data && response.data.exists;
    const specialCharacterError = response?.message?.data?.name === 'Name cannot contain invalid characters.';
    const invalidClassName =
      response?.data?.name === 'Name cannot contain invalid characters.' || typeof response.data === 'undefined';

    // If exists is null then the second parameter to this action indicates an API error
    yield put(setClassroomNameValidated(exists, failed, invalidClassName, specialCharacterError));
  }
}

function* checkClassCode(action) {
  const classCode = action.classCode.trim();

  // Also need current Org uniqueid
  const orgUniqueId = yield select(state => state.organisations.data[state.classroomPage.orgId].customId);
  const joiningCode = `${orgUniqueId}-${classCode}`;

  // Only start validating once the input's length is greater than zero
  if (classCode.length > 4) {
    const response = yield validateGroupJoiningCodeApi(joiningCode);
    const failed = !response;
    const exists = response && response.data;

    // If exists is null then the second parameter to this action indicates an API error
    yield put(setClassroomCodeValidated(exists, failed, classCode));
  }
}

/**
 * This looks at the original classroom lists and the selected lists when the user submits the form and works out what has changed
 */
function* calculateListDiffs() {
  const { teachersAdded, studentsAdded, teachersRemoved, studentsRemoved } = yield select(state => {
    // Just to make the subsequent code easier to read:
    const originalClassroomState = state.classrooms.data[state.classroomPage.classroomId];
    const editClassroomState = state.classroomEdit;

    return {
      // Data to change: Derive diffs:
      teachersAdded: editClassroomState.selectedTeacherIds.filter(
        id => !originalClassroomState.teacherIdList.includes(id)
      ),
      studentsAdded: editClassroomState.selectedStudentIds.filter(
        id => !originalClassroomState.studentIdList.includes(id)
      ),
      teachersRemoved: originalClassroomState.teacherIdList.filter(
        id => !editClassroomState.selectedTeacherIds.includes(id)
      ),
      studentsRemoved: originalClassroomState.studentIdList.filter(
        id => !editClassroomState.selectedStudentIds.includes(id)
      )
    };
  });

  yield put(setIdsForSubmission(teachersAdded, studentsAdded, teachersRemoved, studentsRemoved));

  return { teachersAdded, studentsAdded, teachersRemoved, studentsRemoved };
}

/**
 * Prepare API params as per lambda/api/schema/edit-class-request-body.json
 */
function* editClass() {
  const {
    organisationId,
    classroomId,
    creatingTeacher,
    name,
    teachersAdded,
    studentsAdded,
    teachersRemoved,
    studentsRemoved,
    // New student limit
    enableStudentLimit,
    studentLimit,
    // Old student limit
    origEnableStudentLimit,
    origStudentLimit,
    isPrimarySchool,
    notifyWithEmailValue,
    // Class joining code
    classJoiningCodeUpdate,
    isClassCodeEnableUpdate,
    classJoiningCode,
    isClassCodeEnable,
    joiningCodeId
  } = yield select(state => {
    // Just to make the subsequent code easier to read:
    const editClassroomState = state.classroomEdit;
    const currentClass = state.classrooms.data[state.classroomPage.classroomId] || {};

    return {
      // Context IDs
      organisationId: state.classroomPage.orgId,
      classroomId: state.classroomPage.classroomId,
      origClassroomName: state.classrooms.data[state.classroomPage.classroomId].name,

      creatingTeacher: state.identity.userId,

      // Data to change
      name: editClassroomState.classroomNameValue.trim().replace(/  +/g, ' '),

      // Diffs should have been calculated by this point
      teachersAdded: editClassroomState.teachersAdded,
      studentsAdded: editClassroomState.studentsAdded,
      teachersRemoved: editClassroomState.teachersRemoved,
      studentsRemoved: editClassroomState.studentsRemoved,

      // New limit values
      enableStudentLimit: editClassroomState.enableStudentLimit,
      studentLimit: editClassroomState.studentLimit,
      // Old limit values
      origEnableStudentLimit: !!state.classrooms.data[state.classroomPage.classroomId].studentLimit,
      origStudentLimit: state.classrooms.data[state.classroomPage.classroomId].studentLimit,
      isPrimarySchool: state.organisations.data[state.identity.currentOrganisationId].role === orgRoles.PRIMARY_SCHOOL,
      notifyWithEmailValue: editClassroomState.notifyWithEmailValue,
      // Class joining code
      classJoiningCodeUpdate: editClassroomState.joiningCode.code,
      isClassCodeEnableUpdate: editClassroomState.joiningCode.enabled,
      classJoiningCode: currentClass.joiningCode.code,
      isClassCodeEnable: currentClass.joiningCode.enabled,
      joiningCodeId: currentClass.joiningCode.id
    };
  });

  // Assemble request body to send to API
  // We always send name
  const payload = {
    creatingTeacher,
    name
  };

  // Only include changed values in payload
  if (teachersAdded.length) {
    payload.teachersAdded = teachersAdded;
  }
  if (studentsAdded.length) {
    const attributeTypeAdded = isPrimarySchool ? 'managedUsersAdded' : 'studentsAdded';
    payload[attributeTypeAdded] = studentsAdded;
  }
  if (teachersRemoved.length) {
    payload.teachersRemoved = teachersRemoved;
  }
  if (studentsRemoved.length) {
    const attributeTypeRemoved = isPrimarySchool ? 'managedUsersArchived' : 'studentsRemoved';
    payload[attributeTypeRemoved] = studentsRemoved;
  }

  payload.notifyWithEmail = isHubMode() ? false : notifyWithEmailValue;
  payload.platformCode = getCurrentPlatform();
  // If the limit has been disabled, clear it
  if (!enableStudentLimit && enableStudentLimit !== origEnableStudentLimit) {
    payload.clearLimit = true;
  } else if (enableStudentLimit && studentLimit !== origStudentLimit) {
    // Payload is still enabled and the value has changed so include it
    payload.limit = studentLimit;
  }

  // If codeEnable is true and class code value change there need to send only class code value.
  const editJoiningCodePayload = {
    ...(isClassCodeEnableUpdate && classJoiningCode !== classJoiningCodeUpdate && { code: classJoiningCodeUpdate }),
    ...(!(classJoiningCode !== classJoiningCodeUpdate && isClassCodeEnableUpdate) &&
      isClassCodeEnable !== isClassCodeEnableUpdate && { enabled: isClassCodeEnableUpdate })
  };

  console.log(
    `[editClassroom Saga] Submitting request to update class joining code with class ID ${classroomId} with payload:`,
    editJoiningCodePayload
  );
  console.log(`[editClassroom Saga] Submitting request to update class with ID ${classroomId} with payload:`, payload);
  const promiseArray = [editClassroomApi(organisationId, classroomId, payload)];
  if (!isEmpty(editJoiningCodePayload)) {
    promiseArray.push(editJoiningCodeApi(organisationId, joiningCodeId, editJoiningCodePayload));
  }

  const [response, editJoiningCodeResponse] = yield all(promiseArray);

  const editResult = response || {};

  if (editJoiningCodeResponse && editJoiningCodeResponse.status !== 'success') {
    editResult.status = editJoiningCodeResponse.status;
  }

  if (editJoiningCodeResponse && editJoiningCodeResponse.status === 'success') {
    editResult.editJoiningCodeData = {
      id: editJoiningCodeResponse.data.joiningCodeId,
      joiningCodeUpdate: editJoiningCodeResponse.data.joiningCodeId !== joiningCodeId,
      joiningCodeEnableUpdate:
        'enabled' in editJoiningCodeResponse.data ? editJoiningCodeResponse.data.enabled : isClassCodeEnableUpdate,
      ...('enabled' in editJoiningCodeResponse.data && {
        joiningCodeEnableUpdateChange: editJoiningCodeResponse.data.enabled
      })
    };
  } else {
    editResult.editJoiningCodeData = {};
  }

  editResult.data = editResult.data || {};

  editResult.data.failedIds =
    editResult.data.failedIds ||
    (editResult.error ? [...teachersAdded, ...studentsAdded, ...teachersRemoved, ...studentsRemoved] : []);

  return editResult;
}
/**
 * This takes the result of a form submission and persists the changes made to our local state
 */
function* persistChangesLocally(failedIds, editJoiningCodeData) {
  const {
    classroomId,
    origClassroomName,
    classroomNameValue,
    teachersAdded,
    studentsAdded,
    teachersRemoved,
    studentsRemoved,
    // New student limit
    enableStudentLimit,
    studentLimit,
    // Old student limit
    origEnableStudentLimit,
    origStudentLimit,
    // Class joining code
    classJoiningCodeUpdate,
    classJoiningCode,
    isClassCodeEnable,
    joiningCodeId
  } = yield select(state => {
    // Just to make the subsequent code easier to read:
    const editClassroomState = state.classroomEdit;
    const currentClass = state.classrooms.data[state.classroomPage.classroomId] || {};

    return {
      // Context IDs
      classroomId: state.classroomPage.classroomId,
      origClassroomName: state.classrooms.data[state.classroomPage.classroomId].name,

      // Data to change
      classroomNameValue: editClassroomState.classroomNameValue,

      // Diffs should have been calculated by this point
      teachersAdded: editClassroomState.teachersAdded,
      studentsAdded: editClassroomState.studentsAdded,
      teachersRemoved: editClassroomState.teachersRemoved,
      studentsRemoved: editClassroomState.studentsRemoved,

      // New limit values
      enableStudentLimit: editClassroomState.enableStudentLimit,
      studentLimit: editClassroomState.studentLimit,
      // Old limit values
      origEnableStudentLimit: !!state.classrooms.data[state.classroomPage.classroomId].studentLimit,
      origStudentLimit: state.classrooms.data[state.classroomPage.classroomId].studentLimit,

      // Class joining code
      classJoiningCodeUpdate: editClassroomState.joiningCode.code,
      classJoiningCode: currentClass.joiningCode.code,
      isClassCodeEnable: currentClass.joiningCode.enabled,
      joiningCodeId: currentClass.joiningCode.id
    };
  });

  if (editJoiningCodeData.id !== joiningCodeId || isClassCodeEnable !== editJoiningCodeData.joiningCodeEnableUpdate) {
    const joiningCodeData = {
      id: editJoiningCodeData.id || joiningCodeId,
      code: editJoiningCodeData.id !== joiningCodeId ? classJoiningCodeUpdate : classJoiningCode,
      ...('joiningCodeEnableUpdate' in editJoiningCodeData && {
        enabled: editJoiningCodeData.joiningCodeEnableUpdate
      })
    };
    yield put(updateClassJoiningCode(classroomId, joiningCodeData));
  }

  // Only include changed values in payload
  if (origClassroomName !== classroomNameValue) {
    console.log(`[classroomEdit Saga] Changing name of class ${classroomId} locally:`, classroomNameValue);
    yield put(updateClassroomName(classroomId, classroomNameValue));
  }

  if (teachersAdded.length) {
    const successfullyAddedTeacherIds = teachersAdded.filter(id => !failedIds.includes(id));
    yield put(addTeachersToClassroom(classroomId, successfullyAddedTeacherIds));
    yield pollClassTeachersAdded(successfullyAddedTeacherIds);
  }
  if (studentsAdded.length) {
    const successfullyAddedStudentIds = studentsAdded.filter(id => !failedIds.includes(id));
    console.log(`[classroomEdit Saga] Adding students to class ${classroomId} locally:`, successfullyAddedStudentIds);
    yield put(addStudentsToClassroom(classroomId, successfullyAddedStudentIds));
    yield pollClassStudentsAdded(successfullyAddedStudentIds);
  }
  if (teachersRemoved.length) {
    const successfullyRemovedTeacherIds = teachersRemoved.filter(id => !failedIds.includes(id));
    yield put(removeTeachersFromClassroom(classroomId, successfullyRemovedTeacherIds));
    yield put(removeSelectedTeacherIds(successfullyRemovedTeacherIds));
    yield pollClassTeachersRemoved(successfullyRemovedTeacherIds);
  }
  if (studentsRemoved.length) {
    const successfullyRemovedStudentIds = studentsRemoved.filter(id => !failedIds.includes(id));
    yield put(removeStudentsFromClassroom(classroomId, successfullyRemovedStudentIds));
    yield put(removeSelectedStudentIds(successfullyRemovedStudentIds));
    yield pollClassStudentsRemoved(successfullyRemovedStudentIds);
  }

  // If the limit has been disabled, clear it
  if (!enableStudentLimit && enableStudentLimit !== origEnableStudentLimit) {
    console.log(`[classroomEdit Saga] Clearing student limit on class ${classroomId} locally`);
    yield put(updateClassroomLimit(classroomId, null));
  } else if (enableStudentLimit && studentLimit !== origStudentLimit) {
    console.log(`[classroomEdit Saga] Setting student limit on class ${classroomId} locally:`, studentLimit);
    yield put(updateClassroomLimit(classroomId, studentLimit));
  }
}

/**
 * This reset the edit form with the data from the currently viewed Class
 */
export function* triggerLoadClassroomEdit() {
  const classroomData = yield select(state => state.classrooms.data[state.classroomPage.classroomId]);

  yield put(loadClassroomEdit(classroomData));
}

function* populateClassroomIdToArchive() {
  const { classId, orgId } = yield select(state => ({
    classId: state.classroomPage.classroomId,
    orgId: state.classroomPage.orgId
  }));

  yield put(setClassroomsToArchive(classId, orgId));
}

/** Saga Function
 *   Should set up any listeners then enter a loop over the relevant functionality.
 */
export default function* classroomEdit() {
  console.log('[classroomEdit Saga] Beginning');

  // Spin up a listener for classroom page changes
  yield takeLatest(CLOSE_FORM, triggerLoadClassroomEdit);
  yield takeLatest(CLASS_DETAILS_LOADED, triggerLoadClassroomEdit);

  // Spin up a listener for classname value inputs
  yield takeLatest(SET_CLASSROOM_NAME, validateClassname);

  yield takeLatest(SET_CLASS_CODE, checkClassCode);

  yield takeLatest(GOTO_ARCHIVE, populateClassroomIdToArchive);

  while (true) {
    console.log('[classroomEdit Saga] Waiting for user to submit form');
    yield take(EDIT_CLASS);

    console.log('[classroomEdit Saga] Calculating teacher and student list changes');
    yield calculateListDiffs();

    console.log('[classroomEdit Saga] Submitting Edit Class request');
    const editResult = yield editClass();
    const requestFailed = editResult.status !== 'success';

    console.log('[classroomEdit Saga] Edit Class request complete, showing confirmation page');
    yield put(showConfirmation(requestFailed, editResult.data.failedIds, editResult.editJoiningCodeData));

    // Persist changes locally if successful
    if (editResult.status === 'success') {
      yield persistChangesLocally(editResult.data.failedIds, editResult.editJoiningCodeData);
    }

    console.log('[classroomEdit Saga] Waiting for form to be closed');
    yield take(CLOSE_FORM);
    console.log('[classroomEdit Saga] Edit Class form closed, resetting state and saga');

    // This reset the form
    yield triggerLoadClassroomEdit();
  }
}
